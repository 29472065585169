
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {AppCommonUserInformation} from "@/models/app/common/user-information";
import {ResponseIndex} from "@/models/response";

// Components
import AppCoachDashboardRootUserInformationFormUpdateAvatarIndexVue from "@/views/app/coach/dashboard/root/user-information/form/update-avatar.vue";
import AppCoachDashboardRootUserInformationFormUpdateInformationIndexVue from "@/views/app/coach/dashboard/root/user-information/form/update-information.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    AppCoachDashboardRootUserInformationFormUpdateAvatarIndexVue,
    AppCoachDashboardRootUserInformationFormUpdateInformationIndexVue,
  },
})
export default class AppCoachDashboardRootUserInformationIndexVue extends Vue {
  isLoading: boolean | null = false;
  isUserInformationVisible: boolean | null = false;

  userInformation: AppCommonUserInformation = new AppCommonUserInformation();

  getAvatar(data: string): string {
    return data.length ? data : "/images/other/placeholder-avatar.jpg";
  }

  getBirthday(data: string): string {
    return data.length ? moment(data).format("DD.MM.YYYY.") : "N/A";
  }

  get getOrganizationMemberRole(): string {
    let role = "";

    switch (store.getters.getOrganizationMember.role) {
      case "coach":
        role = "Trener";
        break;
      case "fitness-coach":
        role = "Kondicijski trening";
        break;
      case "manager":
        role = "Menadžer";
        break;
      case "mentor":
        role = "Mentor";
        break;
      default:
        role = "Administrator";
    }
    return role;
  }

  async getUserInformation(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/user/information`,
      formData: {
        id: store.getters.getAuthentication.id,
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.userInformation = r.data;
      }
    });
    this.isLoading = false;
  }

  setUserInformationVisibility(): void {
    this.isUserInformationVisible = !this.isUserInformationVisible;
  }

  async created(): Promise<void> {
    await this.getUserInformation();
  }
}
