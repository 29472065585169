
// Vue
import {Options, Vue} from "vue-class-component";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachDashboardNavigationVue from "@/components/coach/dashboard/navigation.vue";
import AppCoachDashboardStatisticsMatchesIndexVue from "@/views/app/coach/dashboard/statistics/matches/index.vue";
import AppCoachDashboardStatisticsTrainingsIndexVue from "@/views/app/coach/dashboard/statistics/trainings/index.vue";
import AppCoachDashboardRootUserInformationIndexVue from "@/views/app/coach/dashboard/root/user-information/index.vue";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachDashboardNavigationVue,
    AppCoachDashboardStatisticsMatchesIndexVue,
    AppCoachDashboardStatisticsTrainingsIndexVue,
    AppCoachDashboardRootUserInformationIndexVue,
  },
})
export default class AppCoachDashboardStatisticsIndexVue extends Vue {}
