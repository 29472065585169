
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import moment from "moment";

// Store
import store from "@/store/index";

// Models
import {AppCommonMatch} from "@/models/app/common/match";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
  ElDialog,
  ElForm,
  ElFormItem,
  ElDatePicker,
  ElButton,
} from "element-plus";
import {Calendar} from "@element-plus/icons-vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    ElDialog,
    ElForm,
    ElFormItem,
    ElDatePicker,
    ElButton,
    Calendar,
  },
})
export default class AppCoachDashboardStatisticsMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
      match_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };
  formDataRules = {
    filter: {
      date: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  moment: any = null;

  matches: Array<AppCommonMatch> = [];
  statistics: Array<any> = [];

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.getFilteredData();
      }
    });
  }

  async getFilteredData(): Promise<void> {
    await this.getMatches();
    await this.getStatistics();

    this.isDialogVisible = false;
  }

  async getStatistics(): Promise<void> {
    this.matches.forEach((match: any) => {
      const statistic = this.statistics.filter((item: any) => {
        return item.match_category_id === match.match_category_id;
      });

      if (statistic.length === 0) {
        const fieldsOfNumbers = [];

        match.match_category.content.fields.forEach((field: any, key: number) => {
          if (field.type === "number") {
            fieldsOfNumbers.push({
              name: field.name,
              count: match.content.fields[key] ? match.content.fields[key].value ?? 0 : 0
            });
          }
        });

        this.statistics.push({
          name: match.match_category.content.name,
          match_category_id: match.match_category_id,
          count: 1,
          content: fieldsOfNumbers,
        });
      } else {
        this.statistics.forEach((statistic: any, statisticKey: number) => {
          if (statistic.match_category_id === match.match_category_id) {
            this.statistics[statisticKey].count = this.statistics[statisticKey].count + 1;

            match.match_category.content.fields.forEach((matchField: any, matchFieldKey: number) => {
              statistic.content.forEach((statisticField: any, statisticFieldKey: number) => {
                if (statisticField.name === matchField.name) {
                  this.statistics[statisticKey].content[statisticFieldKey].count = this.statistics[statisticKey].content[statisticFieldKey].count + match.content.fields[matchFieldKey].value ?? 0
                }
              });
            });
          }
        });
      }
    });
  }

  async getMatches(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/matches`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matches = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;

    await this.getMatches();
    await this.getStatistics();
  }
}
